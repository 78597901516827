// Third party imports
import React from "react";
import { is_empty } from "utils/validations";
import {
  MeetingAddress,
  ListingTypeFieldFormatted,
  StatusField,
} from "../../schedule/BookedSession/components/TableFields/TableFields";
import { InfoField } from "../../schedule/BookedSession/components/TableFields/CustomColumns";
import { CustomersField } from "./components/TableFields/TableFields";
import constants from "constants/constants";
import formConstants from "./constants";
import { OfferingPlanField } from "common/Components/TableFields/TableFields";
import { PAYMENT_DEBIT_MODES_LABELS } from "features/Listings/modules/PricingSection/PricingSection.constants";

export const form_data = {
  name: "Name",
  email: "Email",
  start_date: "From",
  end_date: "To",
  session_name: "Offering Name",
  status: "Status",
  min_price: "Minimum Amount",
  max_price: "Maximum Amount",
  type: "Offering Type",
  clear: "Clear Filter",
  cancel: "Cancel subscription",
  secondary_action: "Cancel",
  primary_cta_text: "Confirm",
};

const listingTypeOptions = constants.schedule_type.map((item) => ({
  ...item,
  label: item.name,
  value: item.id,
}));

const statusOptions = constants.listing_status
  .filter((item) => item.id === 1 || item.id === 3 || item.id === 5)
  .map((item) => ({ ...item, label: item.name, value: item.id }));

export const getTableConfig = ({
  isMyBookingsHidden,
  handleRecordClick,
  isCustomerDetailsHidden,
}) => [
  {
    field: formConstants.form_data.offering,
    headerName: formConstants.form_header.offering,
    valueFormatter: (record) => <OfferingPlanField record={record} />,
    emptyField: "N/A",
    sortable: false,
    width: "200px",
    columnClassName: "word_break",
    ellipsis: true,
    maxCharCount: 25,
  },
  {
    field: formConstants.form_data.offeringType,
    headerName: formConstants.form_header.offeringType,
    valueFormatter: (record) => (
      <ListingTypeFieldFormatted record={record} source="listing_type" />
    ),
    sortable: false,
    noWrap: true,
  },
  {
    field: formConstants.form_data.paymentDebitMode,
    headerName: formConstants.form_header.paymentDebitMode,
    valueFormatter: (record) => {
      const { payment_debit_mode } = record;
      return PAYMENT_DEBIT_MODES_LABELS[payment_debit_mode];
    },
    sortable: false,
    noWrap: true,
  },
  {
    field: formConstants.form_data.staff,
    headerName: formConstants.form_header.staff,
    valueFormatter: (record) =>
      is_empty(record.staff_name) ? "N/A" : record.staff_name,
    sortable: false,
    columnClassName: "word_break",
    ellipsis: true,
    maxCharCount: 30,
    noWrap: true,
  },
  {
    field: formConstants.form_data.address,
    headerName: formConstants.form_header.address,
    valueFormatter: (record) => <MeetingAddress record={record} />,
    emptyField: "N/A",
    sortable: false,
    width: "200px",
  },
  {
    field: formConstants.form_data.customerCount,
    headerName: formConstants.form_header.customerCount,
    valueFormatter: (record) => (
      <CustomersField
        record={record}
        source="unique_customer_count"
        onClick={handleRecordClick}
      />
    ),
    hiddenDesktopColumn: isMyBookingsHidden,
    hidden: isMyBookingsHidden,
    sortable: false,
    noWrap: true,
  },
  {
    field: formConstants.form_data.status,
    headerName: formConstants.form_header.status,
    valueFormatter: (record) => <StatusField record={record} />,
    sortable: false,
    hidden: true,
  },
  {
    field: formConstants.form_data.offering,
    headerName: "Action",
    valueFormatter: (record) => (
      <InfoField onClick={handleRecordClick} record={record} />
    ),
    sortable: false,
    hidden: true,
    hiddenDesktopColumn: isMyBookingsHidden || isCustomerDetailsHidden,
    fixed: "right",
    width: "120px",
  },
];

export const filterConfig = ({ staffMembers, showStaffFilter }) => [
  {
    source: "title",
    placeholder: formConstants.form_header.offering,
    type: "input",
  },
  {
    source: "type",
    placeholder: formConstants.form_header.offeringType,
    type: "dropdown",
    options: listingTypeOptions,
  },
  {
    source: "org_uuid",
    placeholder: formConstants.form_header.staff,
    type: "dropdown",
    options: staffMembers,
    disableContains: true,
    hidden: !showStaffFilter,
  },
  {
    source: "status",
    placeholder: formConstants.form_header.status,
    type: "dropdown",
    options: statusOptions,
  },
];

export const tableChipsFormatter =
  ({ staffMembers }) =>
  (filter, value) => {
    switch (filter) {
      case "title__icontains":
        return `${formConstants.form_header.offering}: ${value}`;
      case "type": {
        const listing = listingTypeOptions?.find((item) => item.id == value);
        return `${formConstants.form_header.offeringType}: ${listing?.label}`;
      }
      case "org_uuid": {
        const staff = staffMembers?.find((item) => item.id == value);
        return `${formConstants.form_data.staff}: ${staff?.label}`;
      }
      case "status": {
        const status = statusOptions?.find((item) => item.id == value);
        return `${formConstants.form_header.status}: ${status?.label}`;
      }
      default:
        return undefined;
    }
  };
