import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import style from "../Style.module.css";

import { is_empty } from "../../../../utils/validations";
import {
  orgPermissions,
  RESOURCE_KEYS,
} from "../../../../utils/OrgPermissions";
import dataProvider from "../../../../data/dataProvider";
import api from "../../../../data/APIs";
import {
  getTableConfig,
  filterConfig,
  tableChipsFormatter,
} from "./AllTransactionTableConfig";

import ExlyTable from "../../../../common/Components/ExlyTable";
import {
  DetailsDrawerFooter,
  WhatsAppButton,
} from "../../../../webpage-leads/components/CustomColumns";
import {
  FilteredEmptyState,
  PAYOUT_STATUS,
  StatusField,
} from "./components/TableFields/TableFields";
import DiscountOfferModal from "./components/DiscountOfferModal";
import LimitedOfferModal from "./components/LimitedOfferModal";
import { TABS_VALUE } from "./data";
import PerformanceView from "./components/PerformanceView";
import { ReactComponent as LegendToggle } from "assets/vectors/trackOrder/legend_toggle.svg";
import ChevronRight from "@material-ui/icons/ChevronRight";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { exportLargeListingData } from "utils/Utils";
import { REPORT_TYPES } from "ui/pages/Reports/constants";
import { useNotifications } from "utils/hooks";
import { useListController } from "react-admin";
import InitiateRefundModal from "./components/InitiateRefundModal/InitiateRefundModal";
import ConfirmationInitiateRefundModal from "./components/InitiateRefundModal/ConfirmationInitiateRefundModal";
import { PaymentStatusModal } from "utils/Payments/PaymentStatusModal";
import { getRAUrlParams } from "utils/urlUtils";
import { useTransactionUpdate } from "./hooks/useTransactionUpdate";
import { emptyStateProps, TRANS_CONSTANTS } from "./transactions.constants";
import { useIsRefundEnabled } from "./utils/AllTransaction.util";
import useOfferingAutoDebitEnabled from "features/Listings/utils/useOfferingAutoDebitEnabled";
import { TRANSACTION_LABEL } from "features/Crm/modules/Transactions/Transactions.constants";
import LearnMoreCta from "features/Common/modules/LearnMore/modules/LearnMoreCta/LearnMoreCta";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import { TRANSACTIONS_LEARN_MORE_HREFS } from "features/Transactions/modules/GSTDetailsField/constants/Transactions.constants";
import useFeatureOnboardingChecker from "common/Components/FeatureOnboarding/useFeatureOnboardingChecker";
import { onboardingFeatureKeys } from "common/Components/FeatureOnboarding/FeatureOnboarding.constants";
import { SignUpBonusActiveRefundsModal } from "features/Account/modules/SignUpBonusActiveRefundsModal/SignUpBonusActiveRefundsModal";
import { useSignUpBonusActiveRefundsModal } from "features/Account/modules/SignUpBonusActiveRefundsModal/utils/useSignUpBonusActiveRefundsModal";
import { useRefundHistoryVerificationStates } from "features/Transactions/modules/RefundHistory/modules/RefundHistoryVerificationStates/utils/useRefundHistoryVerificationStates";
import { LazyLoadWrapper } from "features/Common/LazyLoadWrapper/LazyLoadWrapper";
import { getTransactionsTabConfig } from "features/Crm/modules/Transactions/utils/Transactions.utils";

export const InitiateRefundModalContext = React.createContext();

const AllTransaction = (props) => {
  const is_desktop = useDesktopMediaQuery();
  const history = useHistory();
  const { notify } = useNotifications();

  const { isRefundEnabled } = useIsRefundEnabled();

  const {
    isOnboardedPrevVal: isFeatureVisited,
    flagLoading: isFeatureFlagLoading,
    handleFeatureOnboardChange,
  } = useFeatureOnboardingChecker({
    featureKey: onboardingFeatureKeys.KNOWLEDGE_BASE.ALL_TRANSACTIONS,
    successCallback: (marked) => !marked && handleFeatureOnboardChange(true),
  });

  const { isSignUpBonusRefundsModal } = useSignUpBonusActiveRefundsModal();

  // extracting from helper hook
  const transactionUpdateData = useTransactionUpdate(props);

  const { fetchRefundHistoryVerificationStates } =
    useRefundHistoryVerificationStates();

  useEffect(() => {
    fetchRefundHistoryVerificationStates();
  }, []);

  const {
    initiateRefund,
    handleRowsSelected,
    confirmModalClose,
    handleOnBackClick,
    getTableActions,
    getPaymentOrderStatus,
    paymentStatus,
    setPaymentStatus,
    isOpenInitiateRefundModal,
    selectedRefundType,
    selectedRefundRecords,
    handleSetFilter,
    isOrgPermissionsLoading,
  } = transactionUpdateData;

  const { selectedIds } = useListController(props);
  const { isOfferingAutoDebitEnabled } = useOfferingAutoDebitEnabled();
  const [showModalOffer, setShowModalOffer] = useState(false);
  const [recordData, setRecordData] = useState({});
  const [orgMembersFilter, setOrgMembersFilter] = useState([]);
  const [flow, setFlow] = useState("");
  const [selected_tab, set_selected_tab] = React.useState(
    TABS_VALUE.transaction
  );

  const isCustomerDetailsHidden = orgPermissions.isNavigationItemHidden(
    RESOURCE_KEYS.SECTIONS.ALL_CUSTOMERS
  );

  const hideAllTransactionsPage = orgPermissions.isNavigationItemHidden(
    RESOURCE_KEYS.SUBSECTIONS.MANAGE_TRANSACTIONS.ALL_TRANSACTIONS,
    RESOURCE_KEYS.SECTIONS.MANAGE_TRANSACTIONS
  );

  const showSubscriptionsPage = !orgPermissions.isNavigationItemHidden(
    RESOURCE_KEYS.SUBSECTIONS.MANAGE_TRANSACTIONS.MANAGE_SUBSCRIPTION,
    RESOURCE_KEYS.SECTIONS.MANAGE_TRANSACTIONS
  );

  const tableTitle = showSubscriptionsPage
    ? "Manage Transactions"
    : "Transactions";

  const handleChange = (new_value) => {
    set_selected_tab(new_value);
  };

  const getOrgMembers = async () => {
    try {
      const { data } = await dataProvider.custom_request(
        api.get_assignee_list,
        "GET"
      );
      if (data?.assignment_members) {
        let members = [];

        data.assignment_members.forEach((member) => {
          members.push({
            id: member.org_uuid,
            name: member.member_name,
            label: member.member_name,
            value: member.org_uuid,
          });
        });

        setOrgMembersFilter(members);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (hideAllTransactionsPage) {
      history.push("/");
    }
    dataProvider.postAnalyticalEvent("creatorPage", "allTranx", {
      flow: "creatorPage",
      funnel: "creatorPage",
      ui_component: "Text Button",
      identifier: "All Transaction",
    });
    getOrgMembers();

    if (getRAUrlParams().get(TRANS_CONSTANTS.order)) {
      getPaymentOrderStatus();
    }
  }, []);

  const onClickOffer = (record, source) => {
    setShowModalOffer(true);
    setRecordData(record);
    const currentFlow =
      source === "discount_code" ? "DiscountOffer" : "LimitedOffer";
    setFlow(currentFlow);
  };

  const showStaffFilter =
    orgPermissions.isOwner() ||
    orgPermissions.isMainUser() ||
    orgPermissions.isManager();

  const hidePricePerSlot = orgPermissions.isFieldHidden(
    RESOURCE_KEYS.SECTIONS.MANAGE_TRANSACTIONS,
    RESOURCE_KEYS.SUBSECTIONS.MANAGE_TRANSACTIONS.ALL_TRANSACTIONS,
    RESOURCE_KEYS.FIELDS.MANAGE_TRANSACTIONS.ALL_TRANSACTIONS.PRICE_PER_SLOT
  );

  const hideTransactionAmount = orgPermissions.isFieldHidden(
    RESOURCE_KEYS.SECTIONS.MANAGE_TRANSACTIONS,
    RESOURCE_KEYS.SUBSECTIONS.MANAGE_TRANSACTIONS.ALL_TRANSACTIONS,
    RESOURCE_KEYS.FIELDS.MANAGE_TRANSACTIONS.ALL_TRANSACTIONS.TRANSACTION_AMOUNT
  );

  const hideNumberOfUnits = orgPermissions.isFieldHidden(
    RESOURCE_KEYS.SECTIONS.MANAGE_TRANSACTIONS,
    RESOURCE_KEYS.SUBSECTIONS.MANAGE_TRANSACTIONS.ALL_TRANSACTIONS,
    RESOURCE_KEYS.FIELDS.MANAGE_TRANSACTIONS.ALL_TRANSACTIONS.NO_OF_UNITS
  );

  const hidePaymountAmount = orgPermissions.isFieldHidden(
    RESOURCE_KEYS.SECTIONS.MANAGE_TRANSACTIONS,
    RESOURCE_KEYS.SUBSECTIONS.MANAGE_TRANSACTIONS.ALL_TRANSACTIONS,
    RESOURCE_KEYS.FIELDS.MANAGE_TRANSACTIONS.ALL_TRANSACTIONS.PAYOUT_AMOUNT
  );

  const hidePayoutStatus = orgPermissions.isFieldHidden(
    RESOURCE_KEYS.SECTIONS.MANAGE_TRANSACTIONS,
    RESOURCE_KEYS.SUBSECTIONS.MANAGE_TRANSACTIONS.ALL_TRANSACTIONS,
    RESOURCE_KEYS.FIELDS.MANAGE_TRANSACTIONS.ALL_TRANSACTIONS.PAYOUT_STATUS
  );

  const hidePayoutDate = orgPermissions.isFieldHidden(
    RESOURCE_KEYS.SECTIONS.MANAGE_TRANSACTIONS,
    RESOURCE_KEYS.SUBSECTIONS.MANAGE_TRANSACTIONS.ALL_TRANSACTIONS,
    RESOURCE_KEYS.FIELDS.MANAGE_TRANSACTIONS.ALL_TRANSACTIONS.PAYOUT_DATE
  );

  const footerProps = {
    primarybtn: "View performance",
    primaryClick: () => handleChange(TABS_VALUE.performance),
    hidePrimarybtn: false,
    isFullLayout: !is_desktop,
  };

  const showCheckboxesStaff =
    !isOrgPermissionsLoading &&
    orgPermissions.hasFeatureWriteAccess(TRANS_CONSTANTS.all_transaction);

  return (
    <div>
      {selected_tab === TABS_VALUE.performance ? (
        <div
          onClick={() => handleChange(TABS_VALUE.transaction)}
          className={`${style.view_performance_navbar} p-3 pointer d-flex align-items-center`}
        >
          <KeyboardBackspace />
          <b className={`ml-1`}>View performance</b>
        </div>
      ) : is_desktop ? (
        <div className={`${style.header} component-wrapper`}>
          <>
            <div className={`${style.title}`}>{tableTitle}</div>

            <div className="d-flex">
              <div
                onClick={() => handleChange(TABS_VALUE.performance)}
                className={`action_text d-flex align-items-center pointer mr-3`}
              >
                <LegendToggle />
                <b className={`ml-1 ${style.view_performance_title}`}>
                  View&nbsp;performance
                </b>
                <ChevronRight />
              </div>

              <LearnMoreCta
                href={TRANSACTIONS_LEARN_MORE_HREFS.MANAGE_TRANSACTIONS}
              />
            </div>
          </>
        </div>
      ) : null}

      {selected_tab === TABS_VALUE.performance ? (
        <PerformanceView />
      ) : (
        <div
          className={`${
            is_desktop ? "component-wrapper-x" : "component-wrapper"
          }`}
        >
          <ExlyTable
            ra_props={{
              ...props,
            }}
            entityName={TRANSACTION_LABEL}
            noExportButton={!orgPermissions.canExport()}
            tableTitle={
              !is_desktop && (
                <div className="d-flex justify-content-between align-items-center">
                  {tableTitle}{" "}
                  <LearnMoreCta
                    href={TRANSACTIONS_LEARN_MORE_HREFS.MANAGE_TRANSACTIONS}
                  />
                </div>
              )
            }
            hasMobileFooter={true}
            footerProps={footerProps}
            tabConfig={showSubscriptionsPage && getTransactionsTabConfig()}
            selectedTab="all-transactions"
            layoutProps={{
              paddingDesktop: "0",
              paddingMobile: "0",
              paddingBottom: "120px",
              noMobileBoxShadow: true,
              showFixedBars: true,
            }}
            customHandleExport={({ filterValues, isEmpty }) =>
              exportLargeListingData({
                filterValues,
                reportType: REPORT_TYPES.TRANSACTIONS,
                notify,
                isEmpty,
              })
            }
            columnConfig={getTableConfig(
              hidePricePerSlot,
              hideTransactionAmount,
              onClickOffer,
              hideNumberOfUnits,
              hidePaymountAmount,
              hidePayoutStatus,
              hidePayoutDate,
              isCustomerDetailsHidden,
              isOfferingAutoDebitEnabled
            )}
            tableFilters={filterConfig(
              orgMembersFilter,
              showStaffFilter,
              isOfferingAutoDebitEnabled
            )}
            tableChipsFormatter={tableChipsFormatter(orgMembersFilter)}
            renderPrimaryColumnRightNode={(record) => {
              return (
                <>
                  {is_empty(record.customer_phone) ||
                  isCustomerDetailsHidden ? null : (
                    <WhatsAppButton
                      record={record}
                      source="customer_phone"
                      countryCodeKey="customer_country_code"
                    />
                  )}
                </>
              );
            }}
            filteredEmptyState={<FilteredEmptyState />}
            fieldsLeftPadded
            borderedFields
            drawerFieldsBordered
            fieldsAlignment="space-between"
            drawerFieldsAlignment="space-between"
            recordFooterVariant="secondary"
            getRecordFooterClassName={(record) => {
              const status_variant = PAYOUT_STATUS[record.payout_status]?.name;
              if (!status_variant) return "";
              return style[`all_transaction_mobile_footer_${status_variant}`];
            }}
            customPrimaryAction={{
              label: "See More",
              onClick: (
                row,
                rows,
                setDrawerConfig,
                setActionsDrawerConfig,
                rowIndex
              ) =>
                setDrawerConfig({
                  drawerActionType: "view",
                  open: true,
                  row,
                  rowIndex,
                }),
            }}
            renderDrawerFooter={(record) =>
              !isCustomerDetailsHidden && (
                <DetailsDrawerFooter
                  record={record}
                  source="customer_phone"
                  countryCodeKey="customer_country_code"
                />
              )
            }
            renderSecondaryAction={(record) => (
              <StatusField record={record} source="payout_status" />
            )}
            primaryKey="id"
            tableActions={
              (showCheckboxesStaff &&
                !is_empty(selectedIds) &&
                isRefundEnabled) ||
              (!is_empty(selectedIds) && isRefundEnabled)
                ? getTableActions
                : null
            }
            checkboxSelection={showCheckboxesStaff && isRefundEnabled}
            showSelectAllcheckBox={isRefundEnabled}
            selected={selectedIds}
            onRowSelected={handleRowsSelected}
            setFilters={handleSetFilter}
            emptyStateProps={{
              ...emptyStateProps,
              isLoading: isFeatureFlagLoading,
              playerProps: {
                playing: !isFeatureFlagLoading && !isFeatureVisited, // autoplay the knowledge base video on the first visit to this feature
              },
            }}
          />
          <LazyLoadWrapper loadingCondition={isSignUpBonusRefundsModal}>
            <SignUpBonusActiveRefundsModal />
          </LazyLoadWrapper>
          <DiscountOfferModal
            open={showModalOffer && flow !== "LimitedOffer"}
            onClose={() => {
              setShowModalOffer(false);
            }}
            data={recordData}
          />

          <LimitedOfferModal
            open={showModalOffer && flow === "LimitedOffer"}
            onClose={() => {
              setShowModalOffer(false);
            }}
            data={recordData}
          />

          <InitiateRefundModalContext.Provider value={transactionUpdateData}>
            {isOpenInitiateRefundModal.refundModal ? (
              <InitiateRefundModal />
            ) : null}
            <ConfirmationInitiateRefundModal
              open={isOpenInitiateRefundModal.confirmModal}
              onClose={confirmModalClose}
              onConfirm={() =>
                initiateRefund(
                  Object.keys(selectedRefundRecords),
                  selectedRefundType
                )
              }
              handleOnBackClick={handleOnBackClick}
            />
          </InitiateRefundModalContext.Provider>
          {!is_empty(paymentStatus) && (
            <PaymentStatusModal
              payment_status={paymentStatus}
              set_payment_status={setPaymentStatus}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default withComponentLibraryTheme(AllTransaction);
