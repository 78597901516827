const constants = {
  form_data: {
    offering: "listing_name",
    offeringType: "listing_type",
    paymentDebitMode: "Debit Type",
    staff: "staff_name",
    address: "listing_address",
    customerCount: "unique_customer_count",
    status: "listing_status",
  },
  form_header: {
    offering: "Offering Name",
    offeringType: "Type",
    paymentDebitMode: "Debit Type",
    staff: "Assigned to",
    address: "Meeting Link/Location",
    customerCount: "No. of Customers",
    status: "Status",
  },
};
export default constants;
