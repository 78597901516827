import { is_empty } from "utils/validations";
import { TRANSACTIONS_REFUND_OPTIONS } from "../transactions.constants";
import useFeaturesActivationStatus from "features/FeatureActivationStatus/utils/useFeaturesActivationStatus";
import { feature_activation_keys } from "features/FeatureActivationStatus/FeatureActivationStatus.constants";
import { PAYMENT_OPTION_KEYS } from "features/Account/modules/PaymentOptions/constants/PaymentOptions.constants";
import { useAdminPanelStore } from "features/AdminPanel/store/useAdminPanelStore";

export const useIsRefundEnabled = () => {
  const { loading, featuresActivationStatus } = useFeaturesActivationStatus();
  const sectionConfig = useAdminPanelStore((state) => state.sectionConfig);
  const isRefundToggleOn =
    sectionConfig[PAYMENT_OPTION_KEYS.CUSTOMER_REFUND]?.value; //@DEV, This value becomes true when refunds is turned on from myAccount section of CT.

  const isRefundEnabled =
    (!loading &&
      featuresActivationStatus[
        feature_activation_keys.is_refunds_enabled.key
      ]) ||
    isRefundToggleOn;
  return { isRefundEnabled };
};

export const isCustomRefundAmountType = (refundType) =>
  refundType === TRANSACTIONS_REFUND_OPTIONS.REFUND_CUSTOM_AMOUNT;

// this function is accepting string as well as numeric value for `refundAmount`
// TODO: fix this
export const validateRefundAmount = ({
  refundAmount,
  transactionAmount,
  selectedRecords,
}) => {
  if (
    +refundAmount > 0 &&
    Object.values(selectedRecords).some((record) => record.is_international)
  )
    return "Only refund of 0 is allowed for international transactions";

  if (is_empty(refundAmount)) {
    return "Required";
  } else if (+refundAmount < 0 || +refundAmount > transactionAmount) {
    return "Please enter a refund amount greater than or equal to 0 and less than or equal to the transaction amount";
  }
  return null;
};
